/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Influenciado por ", React.createElement(_components.a, {
    href: "http://planet.communia.org/content/cap-una-lliure-distribucio-de-converses"
  }, "una lectura muy interesante"), ", he decidido abrir ", React.createElement(_components.a, {
    href: "/api/"
  }, "una nueva página en el blog"), ", donde se describen los recursos a los que es posible acceder en este blog, me gustaría ir añadiendo recursos de web semántica y mejoras en el blog, para ello he empezado con las ", React.createElement(_components.a, {
    href: "https://webmention.io/"
  }, "Webmentions"), ", con ", React.createElement(_components.a, {
    href: "https://wordpress.org/plugins/webmention/"
  }, "un sencillo plugin de wordpress"), ". ¿Algún voluntario que quiera hacer una Webmention a este blog?"), "\n", React.createElement(_components.p, null, "La idea es comentar brevemente con nuevos posts cada mejora que incluya para que quién me siga la pueda probar en su propio blog si le gusta."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
